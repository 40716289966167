import { Dialog, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton/IconButton";
import Link from "@mui/material/Link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import {
	Availability,
	EmploymentType,
	JobPosition,
	Salary,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import {
	JobType,
	MedicalDivision,
} from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import {
	getCompanyDetailsByJobOfferAPI,
	getCompanyLogoAPI,
} from "../../core/apiFunctions";
import {
	extendedJobOfferDTO,
	externalJobOfferDTO,
	jobApplicationDTO,
} from "../../core/dto/dto.models";
import { enumToString } from "../../core/helperConst";
import { useNavigate } from "react-router-dom";

interface dialogProps {
	open: boolean;
	onClose: React.MouseEventHandler<Element>;
	jobDetail:
		| {
				jobType: "Internal";
				jobData: extendedJobOfferDTO;
		  }
		| {
				jobType: "External";
				jobData: externalJobOfferDTO;
		  }
		| {
				jobType: "Application";
				jobData: jobApplicationDTO;
		  }
		| undefined;
	setJobDetail: React.Dispatch<
		React.SetStateAction<
			| {
					jobType: "Internal";
					jobData: extendedJobOfferDTO;
			  }
			| {
					jobType: "External";
					jobData: externalJobOfferDTO;
			  }
			| {
					jobType: "Application";
					jobData: jobApplicationDTO;
			  }
			| undefined
		>
	>;
	bookmarkClick: (id: string) => void;
	isBookmark: boolean;
}

const JobDetailsDialog = ({
	open,
	onClose,
	jobDetail,
	setJobDetail,
	bookmarkClick,
	isBookmark,
}: dialogProps) => {
	const { t } = useTranslation();
	const [companyLogo, setCompanyLogo] = useState<string>();
	const navigate = useNavigate();

	// To get the company logo
	useEffect(() => {
		jobDetail?.jobType === "Internal" &&
			getCompanyDetailsByJobOfferAPI(jobDetail?.jobData.id).then((res) => {
				getCompanyLogoAPI(res.data.id).then((response) => {
					const url = URL.createObjectURL(response.data);
					setCompanyLogo(url);
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobDetail?.jobData]);

	const getCompanyLogo = (clientName: string) => {
		if (clientName.toLowerCase().includes("jobs")) {
			return images.JobChLogo;
		} else if (clientName.toLowerCase().includes("jobup")) {
			return images.JobupLogo;
		} else if (clientName.toLowerCase().includes("alpha")) {
			return images.AlphaLogo;
		} else if (clientName.toLowerCase().includes("ictcareer")) {
			return images.IctcareerChLogo;
		} else if (clientName.toLowerCase().includes("ingjobs")) {
			return images.IngjobsChLogo;
		} else if (clientName.toLowerCase().includes("jobs4finance")) {
			return images.Jobs4financeChLogo;
		} else if (clientName.toLowerCase().includes("jobs4sales")) {
			return images.Jobs4salesChLogo;
		} else if (clientName.toLowerCase().includes("medtalents")) {
			return images.MedtalentsChLogo;
		} else if (clientName.toLowerCase().includes("topjobs")) {
			return images.TopjobsChLogo;
		} else if (clientName.toLowerCase().includes("jobscout24")) {
			return images.Jobscout24Logo;
		} else if (clientName.toLowerCase().includes("jobcloud")) {
			return images.JobcloudLogo;
		} else if (clientName.toLowerCase().includes("jobwinner")) {
			return images.JobWinnerLogo;
		} else return images.JobChLogo;
	};

	return (
		<>
			<Dialog
				className="job-details-dialog job-listing"
				open={open}
				onClose={onClose}
				scroll="body"
				maxWidth="md"
			>
				<DialogContent>
					<IconButton
						aria-label="close"
						className="close-btn"
						onClick={onClose}
						title="Close"
					>
						<img src={images.CloseLarge} alt="Close" />
					</IconButton>
					<div className="job-title">
						<div className="left">
							<img
								className={
									jobDetail?.jobType === "External" ? "clientLogo" : ""
								}
								src={
									jobDetail?.jobType === "External"
										? getCompanyLogo(jobDetail?.jobData.clientName)
										: companyLogo || images.MyCompany
								}
								alt="Company Logo"
							/>
							<div className="content">
								<p>{jobDetail?.jobData.title}</p>
								{jobDetail?.jobType === "External" && (
									<ul>
										<li>{jobDetail.jobData.company}</li>
										<li>
											{jobDetail.jobData.city}
											{jobDetail.jobData.postalCode
												? `, ${jobDetail.jobData.postalCode}`
												: ""}
										</li>
									</ul>
								)}
							</div>
						</div>
						{jobDetail?.jobType === "Internal" && (
							<IconButton
								title={t("chat")}
								onClick={() =>
									navigate(
										"/chat/" +
											(jobDetail.jobData as extendedJobOfferDTO).userId +
											"/" +
											t("message.hello.general") +
											t("message.intentionApply") +
											'"' +
											jobDetail.jobData.title +
											'" ' +
											t("message.at") +
											" " +
											(jobDetail.jobData as extendedJobOfferDTO).companyName +
											"."
									)
								}
							>
								<img src={images.Chat} alt="Bookmark" />
							</IconButton>
						)}
						{/* <IconButton
							title="Bookmark"
							className={isBookmark ? "active" : ""}
							onClick={(e) => {
								e.stopPropagation();
								bookmarkClick(jobDetail?.jobData.id || "");
							}}
						>
							<img
								src={isBookmark ? images.BookmarkActive : images.Bookmark}
								alt="Bookmark"
							/>
						</IconButton> */}
					</div>
					{jobDetail?.jobType === "Internal" && (
						<>
							<Grid container spacing={0} className="job-details">
								<Grid size={{ xs: 12, sm: 6, md: 3 }}>
									<small>{t("medicalDivision")}</small>
									<span>
										{t(
											enumToString(MedicalDivision, jobDetail.jobData.division)
										)}
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6, md: 3 }}>
									<small>{t("jobType")}</small>
									<span>
										{t(enumToString(JobType, jobDetail.jobData.jobType))}
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6, md: 3 }}>
									<small>{t("jobPosition")}</small>
									<span>
										{t(
											enumToString(JobPosition, jobDetail.jobData.jobPosition)
										)}
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6, md: 3 }}>
									<small>{t("workLoad")}</small>
									<span>
										{t(enumToString(Workload, jobDetail.jobData.workload))}
									</span>
								</Grid>
							</Grid>

							<Grid
								container
								spacing={{ xs: 3, sm: 4 }}
								className="job-summary"
							>
								<Grid size={{ xs: 12, sm: 6 }}>
									<img src={images.Jobs} alt="Jobs" />
									<span>
										<span>{t("employmentType")}:</span>
										<small>
											{t(
												enumToString(
													EmploymentType,
													jobDetail.jobData.employmentType
												)
											)}
										</small>
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6 }}>
									<img src={images.LocationGrey} alt="Location Grey" />
									<span>
										<span>{t("address")}:</span>
										<small>
											{jobDetail.jobData.state
												? `${t(enumToString(State, jobDetail.jobData.state))},`
												: ""}
											{t(enumToString(Country, jobDetail.jobData.country))}
										</small>
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6 }}>
									<img src={images.Coin} alt="Coin" />
									<span>
										<span>{t("headerSalary")}:</span>
										<small>
											{t(enumToString(Salary, jobDetail.jobData.salary))}
										</small>
									</span>
								</Grid>
								<Grid size={{ xs: 12, sm: 6 }}>
									<img src={images.Clock} alt="Clock" />
									<span>
										<span>{t("headerAvailability")}:</span>
										<small>
											{t(
												enumToString(
													Availability,
													jobDetail.jobData.availability
												)
											)}
										</small>
									</span>
								</Grid>
							</Grid>
						</>
					)}

					<div
						className="job-description"
						dangerouslySetInnerHTML={{
							__html: jobDetail?.jobData.description || "",
						}}
					></div>
					{jobDetail?.jobType === "External" && (
						<Link
							href={jobDetail.jobData.url}
							className="site-link"
							title={t("checkOutJobsCH", {
								clientName: jobDetail?.jobData.clientName,
							})}
							target="_blank"
						>
							<img src={images.Globe} alt="Globe" />
							<span>
								{t("checkOutJobsCH", {
									clientName: jobDetail?.jobData.clientName,
								})}
							</span>
						</Link>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default JobDetailsDialog;
