import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import {
	Availability,
	EducationLevel,
	EmploymentType,
	JobPosition,
	Salary,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { internalJobDTO } from "../../core/dto/dto.models";
import { idealCandidateDTO } from "../../core/dto/user.models";
import { enumToString } from "../../core/helperConst";

type Props = {
	jobProperties: internalJobDTO | idealCandidateDTO;
	title: string;
};

export const MatchingBaseDetailCard = ({ jobProperties, title }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="card">
			<h6>{t(title)}</h6>
			<ul>
				<li>
					<div className="icon">
						<img src={images.Globe} alt="Globe" />
					</div>
					<span>
						<span>{t("country")}</span>
						<small>
							{jobProperties?.country
								? t(enumToString(Country, jobProperties?.country))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.Location} alt="Location" />
					</div>
					<span>
						<span>
							{jobProperties?.country === Country.Switzerland
								? t("canton")
								: t("state")}
						</span>
						<small>
							{jobProperties?.state
								? t(enumToString(State, jobProperties?.state))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.JobsActive} alt="Job" />
					</div>
					<span>
						<span>{t("preferredTypeOfEmployment")}</span>
						<small>
							{jobProperties?.employmentType
								? t(enumToString(EmploymentType, jobProperties?.employmentType))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.ClockActive} alt={"clock"} />
					</div>
					<span>
						<span>{t("headerAvailability")}</span>
						<small>
							{jobProperties?.availability
								? t(enumToString(Availability, jobProperties?.availability))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.Division} alt="Location" />
					</div>
					<span>
						<span>{t("headerDivision")}</span>
						<small>
							{jobProperties?.division
								? t(enumToString(MedicalDivision, jobProperties?.division))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.Badge} alt="Badge" />
					</div>
					<span>
						<span>{t("position")}</span>
						<small>
							{jobProperties?.jobPosition
								? t(enumToString(JobPosition, jobProperties?.jobPosition))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.JobTick} alt="Job" />
					</div>
					<span>
						<span>{t("workLoad")}</span>
						<small>
							{jobProperties?.workload
								? t(enumToString(Workload, jobProperties?.workload))
								: "-"}
						</small>
					</span>
				</li>
				<li>
					<div className="icon">
						<img src={images.Money} alt="Salary" />
					</div>
					<span>
						<span>{t("headerSalary")}</span>

						<small>
							{jobProperties?.salary
								? t(enumToString(Salary, jobProperties?.salary))
								: "-"}
						</small>
					</span>
				</li>
				{/* <li>
					<div className="icon">
						<img src={images.Calendar} alt="Calendar" />
					</div>
					<span>
						<span>{t("startDate")}</span>
						<small>-</small>
					</span>
				</li> */}
				<li>
					<div className="icon">
						<img src={images.EducationGreen} alt="Education" />
					</div>
					<span>
						<span>{t("headerEducationLevel")}</span>
						<small>
							{jobProperties?.educationLevel
								? t(enumToString(EducationLevel, jobProperties?.educationLevel))
								: "-"}
						</small>
					</span>
				</li>
			</ul>
		</div>
	);
};
