import { createTheme } from "@mui/material";
import images from "./assets/images";

declare module "@mui/material/styles" {
	interface BreakpointOverrides {
		xxl: true;
	}
}

let theme = createTheme({
	palette: {
		primary: {
			main: "#75a932",
			light: "#9ec370",
			dark: "#527623",
		},
		error: {
			main: "#D80027",
			light: "#e44d68",
			dark: "#97001b",
		},
		warning: {
			main: "#f1af04",
			light: "#f5c74f",
			dark: "#a97a03",
		},
		info: {
			main: "#4081ff",
			light: "#79a7ff",
			dark: "#2d5ab3",
		},
		success: {
			main: "#0ed70a",
			light: "#56e354",
			dark: "#0a9707",
		},
	},

	breakpoints: {
		values: {
			xs: 0,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200,
			xxl: 1600,
		},
	},

	spacing: [0, 4, 8, 16, 24, 32, 40, 48, 64],
});

theme = createTheme(theme, {
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
					"&:hover": {
						textDecoration: "underline",
					},
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: "'Sofia Pro', sans-serif",
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					height: "68px",
					width: "68px",
					borderRadius: "12px",
					[theme.breakpoints.down("xl")]: {
						height: "56px",
						width: "56px",
					},

					img: {
						height: "34px",
						width: "34px",
						[theme.breakpoints.down("xl")]: {
							height: "28px",
							width: "28px",
						},
					},
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				badge: {
					height: "32px",
					width: "32px",
					border: "3px solid #fff",
					borderRadius: "50%",
					fontFamily: '"Sofia Pro", sans-serif',
					fontWeight: "600",
					fontSize: "14px",
					lineHeight: "20px",
					color: "#FFFFFF",
					[theme.breakpoints.down("xl")]: {
						height: "26px",
						width: "26px",
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					"&.language-menu": {
						".MuiMenu-paper": {
							minWidth: "182px",
						},
					},
					"&.user-menu": {
						".MuiMenu-paper": {
							minWidth: "232px",
						},
					},
				},
				paper: {
					borderRadius: "20px",
					boxShadow: "0px 10px 13px rgba(0, 0, 0, 0.13)",
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					padding: "16px 24px",
					fontFamily: '"Sofia Pro", sans-serif',
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "20px",
					color: "#202020",

					a: {
						display: "flex",
						alignItems: "center",
						textDecoration: "none",
						color: "#202020",
					},

					img: {
						display: "block",
						height: "28px",
						width: "28px",
						marginRight: "12px",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: "#FFFFFF",
					fontFamily: '"Sofia Pro", sans-serif',
					borderRadius: "10px",
					textTransform: "unset",
					padding: "10px 20px",
					minHeight: "46px",
					fontWeight: "500",
					fontSize: "16px",
					lineHeight: "16px",
					boxShadow: "none",
				},

				sizeSmall: {
					padding: "10px 18px",
					minHeight: "36px",
					borderRadius: "5px",
					fontWeight: "500",
					fontSize: "16px",
					lineHeight: "16px",
				},

				outlined: {
					color: "#75A932",
				},
			},
		},

		MuiFormControl: {
			styleOverrides: {
				root: {
					width: "100%",
					label: {
						fontFamily: '"Sofia Pro", sans-serif',
						fontWeight: "500",
						fontSize: "18px",
						lineHeight: "18px",
						color: "#202020",
						paddingBottom: "10px",
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					background: "#F9F9F9",
				},
				multiline: {
					padding: "0 !important",
				},
				inputMultiline: {
					height: "103px !important",
				},
			},
		},

		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					paddingRight: "20px",
					borderRadius: "10px",
				},

				notchedOutline: {
					border: "1px solid #ECECEC",
					borderRadius: "10px",
				},

				input: {
					padding: "18.5px 20px 18.5px",
					fontFamily: '"Sofia Pro", sans-serif',
					fontSize: "16px",
					lineHeight: "16px",
					fontWeight: "400",
					color: "#969BA0",
					"&::placeholder": {
						color: "#aaaaaa",
						opacity: "1",
					},
				},
			},
		},

		MuiSelect: {
			styleOverrides: {
				select: {
					padding: "20.5px 20px 16.5px",
				},

				icon: {
					backgroundImage: `url(${images.ExpandArrowThick})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "14px",
					backgroundPosition: "center",
					marginRight: "7px",
					path: {
						display: "none",
					},
				},
			},
		},

		MuiInputAdornment: {
			styleOverrides: {
				root: {
					".MuiIconButton-root": {
						height: "40px",
						width: "40px",
						img: {
							height: "24px",
							width: "24px",
						},
					},
				},
			},
		},

		MuiPickersCalendarHeader: {
			styleOverrides: {
				label: {
					fontFamily: '"Sofia Pro", sans-serif',
				},
			},
		},

		MuiPickersDay: {
			styleOverrides: {
				root: {
					fontFamily: '"Sofia Pro", sans-serif',
					"&.Mui-selected": {
						color: "#fff",
					},
				},
			},
		},

		MuiPickersYear: {
			styleOverrides: {
				yearButton: {
					fontFamily: '"Sofia Pro", sans-serif',
					"&.Mui-selected": {
						color: "#fff",
					},
				},
			},
		},

		MuiChip: {
			styleOverrides: {
				root: {
					minHeight: "50px",
					padding: "12px 15px 12px 20px",
					background: "#F9F9F9",
					borderRadius: "10px",
					borderColor: "#ECECEC",
					minWidth: "160px",
					justifyContent: "space-between",
				},
				label: {
					padding: "5px 17px 5px 0px",
					fontFamily: '"Sofia Pro", sans-serif',
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "16px",
					color: "#969BA0",
				},
				deleteIcon: {
					marginRight: "0",
					marginLeft: "0",
				},
			},
		},

		MuiPagination: {
			styleOverrides: {
				ul: {
					justifyContent: "center",
				},
			},
		},

		MuiPaginationItem: {
			styleOverrides: {
				root: {
					fontFamily: '"Sofia Pro", sans-serif',
					fontWeight: "400",
					fontSize: "18px",
					lineHeight: "140%",
					color: "#202020",
					height: "40px",
					width: "40px",
					borderRadius: "50%",

					"&.Mui-selected": {
						color: "#fff",
					},
				},
			},
		},

		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: '"Sofia Pro", sans-serif',
				},
			},
		},

		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: "24px",
					[theme.breakpoints.down("md")]: {
						margin: "24px",
						maxWidth: "calc(100% - 48px) !important",
					},
					[theme.breakpoints.down("sm")]: {
						margin: "16px",
						maxWidth: "calc(100% - 32px) !important",
					},
				},
			},
		},

		MuiDialogContent: {
			styleOverrides: {
				root: {
					borderRadius: "24px",
					padding: "32px",
					[theme.breakpoints.down("md")]: {
						padding: "24px",
					},
					[theme.breakpoints.down("sm")]: {
						padding: "20px",
					},
				},
			},
		},
	},
});

export default theme;
