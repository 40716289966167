import {
	Button,
	IconButton,
	Link,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import images from "../../assets/images";
import defaultImage from "../../assets/images/logo.png";
import Sidebar from "../../components/Sidebar/Sidebar";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import Header from "../../components/header/Header";
import {
	AcademicDegree,
	EducationLevel,
	EmploymentType,
	ImageType,
	JobPosition,
	LanguageNiveau,
	Salary,
	UserRoles,
	Workload,
	YearsOfExperience,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { useProfileImage } from "../../contexts/ImageContext";
import { useLoading } from "../../contexts/LoadingContext";
import {
	downloadFileAPI,
	getAboutMeAPI,
	getBackgroundPhotoAPI,
	getCertificatesAPI,
	getDreamJobAPI,
	getEducationsAPI,
	getLanguageSkillsAPI,
	getProfilePhotoAPI,
	getSkillsAPI,
	getUserDetailsAPI,
	getUserInformationAPI,
	getWorkExperienceAPI,
} from "../../core/apiFunctions";
import { checkRole, getEmail, getName } from "../../core/claimFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	userInformationDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import { downloadFile } from "../../core/helperFunctions";
import { PdfGenerator } from "../../components/pdfGenerator/PdfGenerator";

export function formatDate(dateString: string) {
	// Create a Date object from the input string
	const date = new Date(dateString);

	// Define an array of month names
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	// Get the month and year
	const month = monthNames[date.getMonth()]; // Get month name
	const year = date.getFullYear(); // Get year

	// Return the formatted date
	return `${month} ${year}`;
}

const MyCv = () => {
	// cv/{userId}

	const { userId } = useParams<{ userId: string }>();
	const [userInformation, setUserInformation] = useState<userInformationDTO>();

	const [profilePicture, setProfilePicture] = useState<string>();
	const [backgroundImage, setBackgroundImage] = useState<string>("");

	// MY-Cv
	const [alignment, setAlignment] = React.useState<string | null>("dreamJob");
	const [userDetail, setUserDetail] = useState<userDetailsDTO>(
		{} as userDetailsDTO
	);
	const [dreamJobDetail, setDreamJobDetail] = useState<internalJobDTO>(
		{} as internalJobDTO
	);
	const [aboutMeDetail, setAboutMeDetail] = useState<aboutMeDTO>(
		{} as aboutMeDTO
	);
	const [educationDetail, setEducationDetail] = useState<educationDTO[]>([]);
	const [workExperienceDetail, setWorkExperienceDetail] = useState<
		workExperienceDTO[]
	>([]);
	const [skillsDetail, setSkillsDetail] = useState<skillDTO[]>([]);
	const [languageSkillsDetail, setLanguageSkillsDetail] = useState<
		languageSkillDTO[]
	>([]);
	const [certificatesDetail, setCertificatesDetail] = useState<
		certificateDTO[]
	>([]);
	const [scrollOffset, setScrollOffset] = useState(0);

	const generateCvRef = useRef<HTMLDivElement>(null);

	const { t } = useTranslation();
	const { images1 } = useProfileImage();
	const { claims } = useContext(AuthenticationContext);
	const { setLoading } = useLoading();

	useEffect(() => {
		setLoading(true);
		if (!userId) {
			getUserDetailsAPI().then((response) => {
				setUserDetail(response.data);
			});
		} else if (userId) {
			window.scrollTo({
				top: 0,
			});
			const fetchUserInformation = async () => {
				const userInformation = await getUserInformationAPI(userId);

				setUserInformation((prevState) => {
					return {
						...prevState,
						...userInformation.data,
					};
				});
				setLoading(false);
			};
			fetchUserInformation();

			const fetchHeader = async () => {
				if (userId) {
					await getProfilePhotoAPI(userId)
						.then((blob) => {
							if (blob !== null)
								setProfilePicture(URL.createObjectURL(blob.data));
							else setProfilePicture(defaultImage);
						})
						.catch((error) => {
							// Profilepicture not found
							setProfilePicture(defaultImage);
						});

					await getBackgroundPhotoAPI(userId)
						.then((blob) => {
							if (blob !== null) {
								const img = new Image();
								img.crossOrigin = "anonymous";
								img.src = URL.createObjectURL(blob.data);
								setBackgroundImage(`url(${img.src})`);
							}
						})
						.catch((error) => {
							// Backgroundimage not found
						});
				}
			};
			fetchHeader();
		}

		getDreamJobAPI(userId).then((response) => {
			setDreamJobDetail(response.data);
		});

		getAboutMeAPI(userId).then((response) => {
			setAboutMeDetail(response.data);
		});

		getEducationsAPI(userId).then((response) => {
			setEducationDetail(response.data);
		});

		getWorkExperienceAPI(userId).then((response) => {
			setWorkExperienceDetail(response.data);
		});

		getSkillsAPI(userId).then((response) => {
			setSkillsDetail(response.data);
		});

		getLanguageSkillsAPI(userId).then((response) =>
			setLanguageSkillsDetail(response.data)
		);

		getCertificatesAPI(userId)
			.then((response) => {
				setCertificatesDetail(response.data);
			})
			.finally(() => setLoading(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			if (screenWidth < 768) {
				setScrollOffset(180);
			} else if (screenWidth < 1440) {
				setScrollOffset(110);
			} else if (screenWidth < 1600) {
				setScrollOffset(120);
			} else {
				setScrollOffset(140);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize(); // Initialize on mount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleExportToPDF = async () => {
		if (generateCvRef.current) {
			try {
				setLoading(true);

				// Find all elements with the class exclude-from-pdf and hide them for export
				const elementsToExclude =
					document.querySelectorAll(".exclude-from-pdf");
				elementsToExclude.forEach((element) => {
					if (element instanceof HTMLElement) {
						element.style.display = "none";
					}
				});

				// Use html2canvas to convert the container content to a canvas
				const canvas = await html2canvas(generateCvRef.current, {
					scale: 2,
					allowTaint: true,
					useCORS: true,
				});

				// Create a new jsPDF instance
				const pdf = new jsPDF("p", "mm", "a4", true);

				// Calculate page width and height in pixels based on the canvas scale
				const pageWidth = pdf.internal.pageSize.getWidth();
				const pageHeight = pdf.internal.pageSize.getHeight();
				const imgWidth = pageWidth - 20; // Adjusting for margins

				// Define padding values
				const paddingTop = 5; // Padding at the top
				const paddingBottom = 5; // Padding at the bottom
				const usablePageHeight = pageHeight - paddingTop - paddingBottom; // Usable height of the page

				// Set the dimensions for slicing the canvas
				const pageCanvasHeight = (usablePageHeight * canvas.width) / imgWidth; // Convert page height to canvas scale
				let position = 0;

				// Loop to add each section of the canvas as a new page
				while (position < canvas.height) {
					// Create a temporary canvas for each page slice
					const pageCanvas = document.createElement("canvas");
					pageCanvas.width = canvas.width;
					pageCanvas.height = pageCanvasHeight;

					const pageContext = pageCanvas.getContext("2d");
					pageContext?.drawImage(
						canvas,
						0,
						-position,
						canvas.width,
						canvas.height
					);

					// Add the sliced image to the PDF
					const imgData = pageCanvas.toDataURL("image/png");
					pdf.addImage(
						imgData,
						"PNG",
						5,
						paddingTop,
						imgWidth,
						(pageCanvas.height * imgWidth) / pageCanvas.width
					);

					// Move the position down for the next page slice
					position += pageCanvasHeight;

					// Add a new page if there's more content left
					if (position < canvas.height) {
						pdf.addPage();
					}
				}

				// Show each element again after the PDF export
				elementsToExclude.forEach((element) => {
					if (element instanceof HTMLElement) {
						element.style.display = "block";
					}
				});

				// Save the PDF
				pdf.save("your-cv.pdf");
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	};

	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string | null
	) => {
		handleScroll(newAlignment || "");
		setAlignment(newAlignment);
	};

	const handleScroll = (id: string) => {
		const element = document.getElementById(id);
		if (element) {
			const topPosition =
				element.getBoundingClientRect().top + window.scrollY - scrollOffset; // Adjusting by 100 pixels
			window.scrollTo({
				top: topPosition,
				behavior: "smooth", // Smooth scrolling
			});
		}
	};

	return (
		<>
			<Helmet>
				{t("titleCV")} | {t("reCREWter")}
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* profile wrapper start */}
					<div className="inner-wrapper profile-wrapper">
						{/* left start */}
						<div className="left">
							{/* user info start  */}
							<div className="user-info card">
								<div
									className="thumbnail"
									style={{
										backgroundImage: `url(${
											userId
												? backgroundImage || images1[ImageType.HeaderImage]
												: images1[ImageType.HeaderImage]
										})`,
									}}
								></div>
								{/* content start */}
								<div className="content">
									<img
										src={
											userId ? profilePicture : images1[ImageType.ProfileImage]
										}
										alt={getName(claims)}
										className={
											userInformation?.isAnonymized ? "blurContent" : ""
										}
									/>
									{/* user details start */}
									<div className="user-details">
										<div className="top">
											<div
												className={`user-name ${
													userInformation?.isAnonymized ? "blurContent" : ""
												}`}
											>
												{userId ? (
													<h4>{userInformation?.name ?? ""}</h4>
												) : (
													<h4>{`${userDetail.firstName ?? ""} ${userDetail.lastName ?? ""}`}</h4>
												)}

												<p>
													{userId
														? enumToString(UserRoles, userInformation?.role)
														: checkRole(claims, UserRoles.Admin)
															? t("administrator")
															: checkRole(claims, UserRoles.Recrewter)
																? t("company")
																: checkRole(claims, UserRoles.Employee)
																	? t("professional")
																	: ""}
												</p>
												{userDetail?.nationality && userDetail?.state && (
													<span className="location">
														<img src={images.Location} alt="Location" />
														<span>
															{enumToString(State, userDetail?.state) !== "None"
																? `${t(enumToString(State, userDetail?.state))}, `
																: ""}
															{t(
																enumToString(
																	Country,
																	Number(userDetail?.nationality)
																)
															)}
														</span>
													</span>
												)}
											</div>
										</div>
										<div className="bottom" data-html2canvas-ignore>
											{userId ? (
												<div
													className={`social-links ${
														userInformation?.isAnonymized ? "blurContent" : ""
													}`}
												>
													{userInformation?.linkedIn && (
														<RouterLink
															to={formatUrlLink(userInformation?.linkedIn)}
															target="_blank"
														>
															<IconButton title="Linkedin">
																<img src={images.Linkedin2} alt="Linkedin" />
															</IconButton>
														</RouterLink>
													)}
													{userInformation?.xing && (
														<RouterLink
															to={formatUrlLink(userInformation?.xing)}
															target="_blank"
														>
															<IconButton title="Xing">
																<img src={images.Xing} alt="Xing" />
															</IconButton>
														</RouterLink>
													)}
													{userInformation?.website && (
														<RouterLink
															to={formatUrlLink(userInformation?.website)}
															target="_blank"
														>
															<IconButton title="Website">
																<img src={images.Globe} alt="Website" />
															</IconButton>
														</RouterLink>
													)}
												</div>
											) : (
												<div className="social-links" aria-disabled>
													{userDetail?.linkedIn && (
														<RouterLink
															to={formatUrlLink(userDetail?.linkedIn)}
															target="_blank"
														>
															<IconButton title="Linkedin">
																<img src={images.Linkedin2} alt="Linkedin" />
															</IconButton>
														</RouterLink>
													)}
													{userDetail?.xing && (
														<RouterLink
															to={formatUrlLink(userDetail?.xing)}
															target="_blank"
														>
															<IconButton title="Xing">
																<img src={images.Xing} alt="Xing" />
															</IconButton>
														</RouterLink>
													)}
													{userDetail?.website && (
														<RouterLink
															to={formatUrlLink(userDetail?.website)}
															target="_blank"
														>
															<IconButton title="Website">
																<img src={images.Globe} alt="Website" />
															</IconButton>
														</RouterLink>
													)}
												</div>
											)}

											<div className="btn-group">
												{userDetail?.email !== getEmail(claims) && (
													<Button
														variant="outlined"
														title="Chat Now"
														to="/chat"
														component={RouterLink}
													>
														<img src={images.Chat} alt="Chat" />
														<span>{t("chatNow")}</span>
													</Button>
												)}
												<Button
													variant="contained"
													title={t("generateCv")}
													onClick={handleExportToPDF}
												>
													{t("generateCv")}
												</Button>
											</div>
										</div>
									</div>
									{/* user details start */}
								</div>
								{/* content end */}
							</div>
							{/* user info end  */}

							{/* profile steps start */}
							<ToggleButtonGroup
								value={alignment}
								exclusive
								onChange={handleAlignment}
								aria-label="text alignment"
								className="cv-steps"
								data-html2canvas-ignore
							>
								<ToggleButton
									value="aboutMe"
									title={t("aboutMe")}
									component={Link}
								>
									{t("aboutMe")}
								</ToggleButton>
								<ToggleButton
									value="education"
									title={t("education")}
									component={Link}
								>
									{t("education")}
								</ToggleButton>
								<ToggleButton
									value="experience"
									title={t("experience")}
									component={Link}
								>
									{t("experience")}
								</ToggleButton>
							</ToggleButtonGroup>
							{/* profile steps end */}

							{/* About Me start */}
							<div className="job-description card" id="aboutMe">
								<h6>{t("aboutMe")}</h6>
								<p>{aboutMeDetail.aboutMe}</p>
							</div>
							{/* About Me end */}

							{/* Dream job desc start */}
							<div className="job-description card" id="aboutMe">
								<h6>{t("dreamJobDescription")}</h6>
								<p>{dreamJobDetail.description}</p>
							</div>
							{/* Dream job desc end */}

							{/* education start */}
							<div className="education card" id="education">
								<h6>{t("Education")}</h6>
								<ul>
									{educationDetail.map((education) => (
										<li key={education.id}>
											<div className="icon">
												<img src={images.Education} alt="Education" />
											</div>
											<div className="details">
												<p>
													{t(
														enumToString(
															AcademicDegree,
															education.academicDegree ?? 0
														)
													)}
												</p>
												<span>{education.institution}</span>
												<small>{`${formatDate(
													education.startDate
												)} - ${formatDate(education.endDate)}`}</small>
											</div>
										</li>
									))}
								</ul>
							</div>
							{/* education end */}

							{/* professional experience start */}
							<div className="professional-experience card" id="experience">
								<h6>{t("professionalExperience")}</h6>
								<ul>
									{workExperienceDetail.map((workExperience) => (
										<li key={workExperience.id}>
											<div className="icon">
												<img src={images.JobsWhite} alt="Jobs" />
											</div>
											<div className="details">
												<p>{workExperience.jobTitle}</p>
												<span>{`${formatDate(
													workExperience.startDate
												)} - ${formatDate(workExperience.endDate)} | ${
													workExperience.address
												}`}</span>
												<span>{workExperience.company}</span>
											</div>
										</li>
									))}
								</ul>
							</div>
							{/* professional experience end */}
						</div>
						{/* left end */}

						{/* right start */}
						<div className="right">
							<div className="card">
								<h6>{t("myDreamJob")}</h6>
								<ul>
									<li>
										<div className="icon">
											<img src={images.Globe} alt="Globe" />
										</div>
										<span>
											<span>{t("country")}</span>
											<small>
												{t(enumToString(Country, dreamJobDetail.country ?? 0))}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Location} alt="Location" />
										</div>
										<span>
											<span>
												{dreamJobDetail.country === Country.Switzerland
													? t("canton")
													: t("state")}
											</span>
											<small>
												{t(enumToString(State, dreamJobDetail.state ?? 0))}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Division} alt="Location" />
										</div>
										<span>
											<span>{t("division")}</span>
											<small>
												{t(
													enumToString(
														MedicalDivision,
														dreamJobDetail.division ?? 0
													)
												)}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.JobsActive} alt="Job" />
										</div>
										<span>
											<span>{t("preferredTypeofEmployment")}</span>
											<small>
												{t(
													enumToString(
														EmploymentType,
														dreamJobDetail.employmentType ?? 0
													)
												)}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Badge} alt="Badge" />
										</div>
										<span>
											<span>{t("position")}</span>
											<small>
												{t(
													enumToString(
														JobPosition,
														dreamJobDetail.jobPosition ?? 0
													)
												)}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.JobTick} alt="Job" />
										</div>
										<span>
											<span>{t("workLoad")}</span>
											<small>
												{t(
													enumToString(Workload, dreamJobDetail.workload ?? 0)
												)}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Money} alt="Globe" />
										</div>
										<span>
											<span>{t("headerSalary")}</span>
											<small>
												{t(enumToString(Salary, dreamJobDetail.salary ?? 0))}
											</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Calendar} alt="Calendar" />
										</div>
										<span>
											<span>{t("startDate")}</span>
											<small>-</small>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.EducationGreen} alt="Education" />
										</div>
										<span>
											<span>{t("headerEducationLevel")}</span>
											<small>
												{t(
													enumToString(
														EducationLevel,
														dreamJobDetail?.educationLevel ?? 0
													)
												)}
											</small>
										</span>
									</li>
								</ul>
							</div>

							<div className="card personal-information">
								<h6>{t("personalInformation")}</h6>
								{userId ? (
									<ul
										className={
											userInformation?.isAnonymized ? "blurContent" : ""
										}
									>
										<li>
											<div className="icon">
												<img src={images.MyProfileActive} alt="User" />
											</div>
											<span>
												<small>{t("fullName")}</small>
												<span>{`${userInformation?.name}`}</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Email} alt="Email" />
											</div>
											<span>
												<small>{t("email")}</small>
												<span>
													{userInformation?.email ? (
														<Link
															href={`mailto:${userInformation.email}`}
															target="_blank"
														>
															{userInformation.email}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("phoneNumber")}</small>
												<span>
													{userInformation?.phoneNumber
														? userInformation.phoneNumber
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("mobileNumber")}</small>
												<span>
													{userInformation?.mobileNumber
														? userInformation.mobileNumber
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.LinkedinPrimary} alt="Linkedin" />
											</div>
											<span>
												<small>{t("linkedin")}</small>
												<span>
													{userInformation?.linkedIn ? (
														<Link
															target="_blank"
															href={formatUrlLink(userInformation?.linkedIn)}
														>
															{userInformation.linkedIn}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.JobsActive} alt="Job" />
											</div>
											<span>
												<small>{t("xing")}</small>
												<span>
													{userInformation?.xing ? (
														<Link
															target="_blank"
															href={formatUrlLink(userInformation?.xing)}
														>
															{userInformation.xing}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Globe} alt="Globe" />
											</div>
											<span>
												<small>{t("website")}</small>
												<span>
													{userInformation?.website ? (
														<Link
															target="_blank"
															href={formatUrlLink(userInformation?.website)}
														>
															{userInformation.website}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
									</ul>
								) : (
									<ul>
										<li>
											<div className="icon">
												<img src={images.MyProfileActive} alt="User" />
											</div>
											<span>
												<small>{t("fullName")}</small>
												<span>{`${userDetail?.firstName} ${userDetail?.lastName}`}</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Email} alt="Email" />
											</div>
											<span>
												<small>{t("email")}</small>
												<span>
													{userDetail?.email ? (
														<Link
															href={`mailto:${userDetail.email}`}
															target="_blank"
														>
															{userDetail.email}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("phoneNumber")}</small>
												<span>
													{userDetail.phoneNumber
														? userDetail.phoneNumber
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Call} alt="Call" />
											</div>
											<span>
												<small>{t("mobileNumber")}</small>
												<span>
													{userDetail.mobileNumber
														? userDetail.mobileNumber
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.LinkedinPrimary} alt="Linkedin" />
											</div>
											<span>
												<small>{t("linkedin")}</small>
												<span>
													{userDetail?.linkedIn ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetail?.linkedIn)}
														>
															{userDetail.linkedIn}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.JobsActive} alt="Job" />
											</div>
											<span>
												<small>{t("xing")}</small>
												<span>
													{userDetail?.xing ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetail?.xing)}
														>
															{userDetail.xing}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Globe} alt="Globe" />
											</div>
											<span>
												<small>{t("website")}</small>
												<span>
													{userDetail?.website ? (
														<Link
															target="_blank"
															href={formatUrlLink(userDetail?.website)}
														>
															{userDetail.website}
														</Link>
													) : (
														"-"
													)}
												</span>
											</span>
										</li>
									</ul>
								)}
							</div>
							<div className="card">
								<h6 className="pb-0">{t("languageSkills")}</h6>
								<ul>
									{languageSkillsDetail.map((skill) => (
										<li key={skill.id}>
											<div className="icon">
												<img src={images.LanguageSkill} alt="User" />
											</div>
											<span>
												<small>{skill.language}</small>
												<span>{`${t(enumToString(LanguageNiveau, skill.niveau) || "")}`}</span>
											</span>
										</li>
									))}
								</ul>
							</div>
							<div className="card">
								<h6 className="pb-0">{t("skills")}</h6>
								<ul>
									{skillsDetail.map((skill) => (
										<li key={skill.id}>
											<div className="icon">
												<img src={images.Skill} alt="User" />
											</div>
											<span>
												<small>{skill.name}</small>
												<span>{`${t(enumToString(YearsOfExperience, skill.years) || "")}`}</span>
											</span>
										</li>
									))}
								</ul>
							</div>
							<div className="card">
								<h6 className="pb-0">{t("certificates")}</h6>
								<ul>
									{certificatesDetail.map((certificate) => (
										<li key={certificate.id}>
											<div className="icon">
												<img src={images.Certificate} alt="User" />
											</div>
											<span>
												<small>{certificate.title}</small>
												<span>{`${formatDate(
													certificate.startDate
												)} - ${formatDate(certificate.endDate)}`}</span>
											</span>
											{!userInformation?.isAnonymized && (
												<img
													src={images.Download}
													alt="download"
													style={{ marginLeft: "25px", cursor: "pointer" }}
													onClick={() => {
														setLoading(true);
														downloadFileAPI(certificate.filePath, userDetail.id)
															.then((response) => {
																// create file link in browser's memory
																const href = URL.createObjectURL(response.data);
																downloadFile(href, certificate.fileName);
															})
															.finally(() => setLoading(false));
													}}
												/>
											)}
										</li>
									))}
								</ul>
							</div>
						</div>
						{/* right end */}
					</div>
					{/* profile wrapper end */}
					<div
						style={{ position: "absolute", top: "-10000px", left: "-10000px" }}
					>
						<PdfGenerator
							aboutMeDetail={aboutMeDetail}
							backgroundImage={backgroundImage}
							certificatesDetail={certificatesDetail}
							dreamJobDetail={dreamJobDetail}
							educationDetail={educationDetail}
							generateCvRef={generateCvRef}
							images1={images1}
							languageSkillsDetail={languageSkillsDetail}
							profilePicture={profilePicture}
							skillsDetail={skillsDetail}
							userDetail={userDetail}
							userId={userId}
							userInformation={userInformation}
							workExperienceDetail={workExperienceDetail}
						/>
					</div>
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}
		</>
	);
};

export default MyCv;
