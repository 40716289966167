import { Button, FormControl, IconButton, OutlinedInput } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import images from "../assets/images";
import { FileType } from "../config/Types/GeneralEnumDefinitions";
import { downloadFileAPI, submitFileAPI } from "../core/apiFunctions";
import { downloadFile } from "../core/helperFunctions";

export default function FileUploadField(props: fileUploadFieldProps) {
	const { t } = useTranslation();

	const {
		accept = "*",
		directUpload = true,
		field,
		videoDescription,
		fileName: initialFileName,
		filePath: initialFilePath,
		type,
		videSource,
		onChange,
		onRemove,
		onNewSource,
		onDownload,
		onUploadDone,
		onError,
		validationMessage,
		uploadTigger,
		isVideo,
		displayName,
	} = props;

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [filePath, setFilePath] = React.useState<string>(initialFilePath);
	const [fileName, setFileName] = React.useState<string>(initialFileName);
	const fileTypes = ["mp4"];

	useEffect(() => {
		setFilePath(initialFilePath);
		setFileName(initialFileName);
	}, [initialFilePath, initialFileName]);

	useEffect(() => {
		if (uploadTigger) {
			console.log("upload trigred ", uploadTigger);
			manualUpload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadTigger]);

	const manualUpload = () => {
		const response = fetch(filePath);
		const blobPromise = response.then((r) => r.blob());
		blobPromise.then((blob) => {
			const file = new File([blob], fileName);
			const event = { target: { files: [file] } };
			upload(event as any);
		});
	};

	const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const dto = {
				file: event.target.files[0],
				type: type,
			};

			submitFileAPI(dto)
				.then((response) => {
					const event = {
						targets: [
							{ value: dto.file.name, name: field },
							{ value: response.data, name: "filePath" },
						],
					};
					onChange(event);

					if (onUploadDone) {
						onUploadDone(response.data, dto.file.name);
					}

					setFilePath(response.data);
					setFileName(dto.file.name);
				})
				.catch((error) => {
					if (onError) {
						if (error.response.data[0].includes("body too large")) {
							onError("error.fileTooLarge");
						} else {
							onError(error.response.data);
						}
					}
				});
		}
	};

	const remove = () => {
		const event = {
			targets: [
				{ value: "", name: field },
				{ value: "", name: "filePath" },
			],
		};
		console.log("remove");
		onChange(event);
		if (onRemove) {
			onRemove();
		}

		setFilePath("");
		setFileName("");
	};

	const download = () => {
		if (onDownload) {
			onDownload();
		} else {
			downloadFileAPI(filePath).then((response) => {
				// create file link in browser's memory
				const href = URL.createObjectURL(response.data);
				downloadFile(href, fileName);
			});
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (directUpload) {
			upload(event);
		} else {
			const file = event.target.files?.[0];
			if (file) {
				const url = URL.createObjectURL(file);
				setFileName(file.name);
				setFilePath(url);

				if (onNewSource) {
					onNewSource(url, file.name);
				}
			}
		}
	};

	const handleFChange = (file: any) => {
		if (file) {
			const url = URL.createObjectURL(file);
			setFileName(file.name);
			setFilePath(url);

			if (onNewSource) {
				onNewSource(url, file.name);
			}
		}
	};
	return (
		<>
			{!isVideo ? (
				<FormControl>
					{displayName && (
						<label htmlFor={field} className="floating-label">
							{t(displayName)}
						</label>
					)}

					{fileName ? (
						<OutlinedInput
							type={"text"}
							value={fileName}
							className="fileInput"
							endAdornment={
								<>
									<IconButton edge="end" onClick={remove}>
										<img src={images.TrashCanGrey} alt={t("delete")} />
									</IconButton>
									<IconButton edge="end" onClick={download}>
										<img src={images.Download} alt={t("download")} />
									</IconButton>
								</>
							}
						/>
					) : (
						<OutlinedInput
							type={"file"}
							onChange={handleChange}
							id={field}
							name={field}
							placeholder={field}
							className="fileInput"
							inputProps={{
								ref: fileInputRef,
							}}
							endAdornment={
								<IconButton
									edge="end"
									onClick={() => {
										if (fileInputRef.current) {
											fileInputRef.current.click();
										}
									}}
								>
									<img src={images.Upload} alt={t("upload")} />
								</IconButton>
							}
						/>
					)}

					{validationMessage && (
						<div className="text-danger" style={{ color: "red" }}>
							{validationMessage}
						</div>
					)}
				</FormControl>
			) : fileName && videSource ? (
				<div className="videoDisplay">
					<video
						className="VideoInput_video mb-2"
						width="400px"
						controls
						src={videSource}
						style={{
							padding: " 0px 10px 15px 0px",
							maxHeight: "300px",
						}}
					/>
					<Button onClick={remove}>
						<img src={images.TrashCanGrey} alt="remove" />
					</Button>
				</div>
			) : (
				<>
					<p>{t(field)}</p>
					{videoDescription && <small>{t(videoDescription)}</small>}

					<FileUploader
						handleChange={handleFChange}
						name="file"
						types={fileTypes}
						accept={accept}
						classes="file-upload"
					>
						<img src={images.Upload} alt="Upload" />
						<p>
							{t("dragOrDrop")}
							<label htmlFor={field}> {t("clickToUpload")}</label>
						</p>
						<span>
							{t("supportedFormatsTitle")}
							<small>{t("supportedVideoFormat")}</small> {t("maxSize")}:
							<small>{t("validCvVideoSize")}</small>
						</span>
						<input
							type="file"
							onChange={handleChange}
							id={field}
							name={field}
							accept={accept}
						/>
					</FileUploader>
				</>
			)}
		</>
	);
}

interface fileUploadFieldProps {
	// defines which field is updated by this component e.g. "imagePath", "videoPath", "filePath"
	field: string;
	videoDescription?: string;
	isVideo?: boolean;
	fileName: string;
	filePath: string;
	videSource?: string;
	// defines which type of file is uploaded
	type: FileType;

	displayName?: string;

	onChange(event: any): void;
	onRemove?(): void;
	onNewSource?(source: string, fileName: string): void;
	onDownload?(): void;
	onUploadDone?(filePath: string, fileName: string): void;
	onError?(error: any): void;

	validationMessage?: string;

	// defines which file types can be uploaded
	accept?: string;

	// if true, file will be uploaded directly after selection
	directUpload?: boolean;

	// is going to be set by parent component to trigger upload
	uploadTigger?: boolean;
}
