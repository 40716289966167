import { Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
	const { t } = useTranslation();
	const navigation = useNavigate();

	return (
		<>
			<Helmet>
				<title>Verify Email | reCREWter</title>
			</Helmet>

			{/* login wrapper start */}
			<div className="auth-wrapper login verifyEmail-wrapper">
				<Container maxWidth="lg">
					{/* inner start */}
					<div className="inner">
						{/* left start */}
						<div className="left">
							<div className="content">
								<img
									src={images.Ellipse1}
									className="ellipse-one"
									alt="Blur Background"
								/>
								<img
									src={images.Ellipse2}
									className="ellipse-two"
									alt="Blur Background"
								/>
								<img src={images.Logo} className="logo" alt="reCREWter" />
								<h3>{t("emailNotVerifiedTitle")}</h3>
							</div>
							{/* <img src={images.Illustration} alt="Illustration" /> */}
						</div>
						{/* left end */}

						{/* right start */}
						<div
							className="right"
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<span
								style={{
									lineHeight: "35px",
									fontSize: "20px",
								}}
							>
								{t("emailNotVerified")}
							</span>
							<Button
								variant="contained"
								title="Login"
								onClick={() => navigation("/login")}
							>
								{t("login")}
							</Button>
						</div>
						{/* right start */}
					</div>
					{/* inner end */}
				</Container>
			</div>
			{/* login wrapper end */}
		</>
	);
};

export default VerifyEmail;
